import * as React from "react";
import { graphql, PageProps } from "gatsby";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";
import { ExchangesQuery } from "../../../graphql-types";
import { NavTab } from "../../components/layout/navigation/navigation";
import Exchanges from "../../components/pages/exchanges/exchanges";

type ExchangesPageProps = PageProps<ExchangesQuery>;

const ExchangesPage: React.FC<ExchangesPageProps> = ({ data }) => {
  return (
    <Layout activeTab={NavTab.Exchanges}>
      <SEO
        title="Top Cryptocurrency Exchanges"
        description="Top cryptocurrency exchanges(DEX) volume, trust score and coins you can buy on them"
      />

      <Exchanges staticData={data} />
    </Layout>
  );
};

export default ExchangesPage;

export const query = graphql`
  query Exchanges {
    allExchangeListItem(sort: { fields: [trust_score], order: [DESC] }) {
      nodes {
        id
        slug
        name
        image
        trade_volume_24h_btc
      }
    }
  }
`;
